var hostname = window.location.hostname;
var isProd = hostname === 'mtzg.qingcijoy.com';
var disable = isProd ? false : true;
window.__bl = {
    config: {
        pid: "egrrccpv71@ef7af2c231f632b",
        appType: "web",
        imgUrl: "https://arms-retcode.aliyuncs.com/r.png?",
        sendResource: true,
        enableLinkTrace: true,
        behavior: true,
        disabled: disable,
        environment: isProd ? 'prod' : 'local',
        page: window.pageName,
    }
}